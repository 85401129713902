import React, { FC, useEffect, useState } from "react";
import FilterProfileDict from "../../Interfaces/FilterProfileDict";
import global_profiles_data from "../../Configuration/global_filter_profiles.json";
import { CgProfile } from "react-icons/cg";
import { Simulate } from "react-dom/test-utils";
import { NO_PROFILE } from "../../Configuration/Constants";
import select = Simulate.select;

const FilterProfiles: FC<{
  filterProfiles: FilterProfileDict;
  setFilterProfiles: any;
  variantFilters: string[];
  appFilters: string[];
  sysNameFilters: string[];
  envFilters: string[];
  itemTypeFilters: string[];
  selectedProfile: string | undefined;
  setSelectedProfile: any;
  settingsOpen: boolean;
  setDiffFromProfile: any;
}> = ({
  filterProfiles,
  setFilterProfiles,
  variantFilters,
  envFilters,
  appFilters,
  sysNameFilters,
  itemTypeFilters,
  selectedProfile,
  setSelectedProfile,
  settingsOpen,
  setDiffFromProfile,
}) => {
  const [textInput, setTextInput] = useState("");
  const [changesDetected, setChangesDetected] = useState(false);
  useEffect(() => {
    if (selectedProfile && selectedProfile === NO_PROFILE) {
      setChangesDetected(false);
      setDiffFromProfile(new Set<string>([""]));
    } else if (
      filterProfiles &&
      selectedProfile &&
      filterProfiles[selectedProfile]
    ) {
      let aux_array: string[] = [];
      aux_array = aux_array.concat([
        ...symmetricDifference(
          envFilters,
          filterProfiles[selectedProfile].envFilters,
        ),
      ]);
      aux_array = aux_array.concat([
        ...symmetricDifference(
          variantFilters,
          filterProfiles[selectedProfile].variantFilters,
        ),
      ]);
      aux_array = aux_array.concat([
        ...symmetricDifference(
          itemTypeFilters,
          filterProfiles[selectedProfile].itemTypeFilters,
        ),
      ]);
      aux_array = aux_array.concat([
        ...symmetricDifference(
          appFilters,
          filterProfiles[selectedProfile].appFilters,
        ),
      ]);
      aux_array = aux_array.concat([
        ...symmetricDifference(
          sysNameFilters,
          filterProfiles[selectedProfile].sysNameFilters,
        ),
      ]);
      setDiffFromProfile(new Set<string>(aux_array));
      if (aux_array.length > 0) {
        setChangesDetected(true);
      } else {
        setChangesDetected(false);
      }
    }
  }, [
    envFilters,
    appFilters,
    sysNameFilters,
    itemTypeFilters,
    variantFilters,
    filterProfiles,
    selectedProfile,
  ]);

  function* symmetricDifference(A: string[], B: string[]) {
    const setA = new Set(A);
    const setB = new Set(B);

    for (const v of setA) {
      if (!setB.has(v)) {
        yield v;
      }
    }
    for (const v of setB) {
      if (!setA.has(v)) {
        yield v;
      }
    }
  }

  const saveProfile = () => {
    if (textInput !== "" && textInput !== NO_PROFILE) {
      const updatedProfiles = {
        ...filterProfiles,
        [textInput]: {
          variantFilters: variantFilters,
          envFilters: envFilters,
          appFilters: appFilters,
          sysNameFilters: sysNameFilters,
          itemTypeFilters: itemTypeFilters,
        },
      };
      setFilterProfiles(updatedProfiles);
      setSelectedProfile(textInput);
      localStorage.setItem("filter_profiles", JSON.stringify(updatedProfiles));
    } else {
      window.alert("InvalidProfileNameError: Set a name for your profile.");
    }
  };

  useEffect(() => {
    setTextInput(selectedProfile || NO_PROFILE);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("profile", String(selectedProfile));
    window.history.replaceState(null, "", `?${searchParams.toString()}`);
  }, [selectedProfile, filterProfiles]);

  const deleteProfile = () => {
    if (selectedProfile) {
      delete filterProfiles[selectedProfile];
      setSelectedProfile(NO_PROFILE);
      setTextInput("");
      localStorage.setItem("filter_profiles", JSON.stringify(filterProfiles));
    }
  };
  const resetToGlobalProfiles = () => {
    const global_profiles: FilterProfileDict = global_profiles_data;
    if (window.confirm(`Are you sure you wish to reset to global profiles?`)) {
      setFilterProfiles(global_profiles);
      localStorage.setItem("filter_profiles", JSON.stringify(global_profiles));
    }
  };
  const editMenu = () => {
    return (
      <div className={"edit-manager"}>
        <button
          className={"std-button-class"}
          title={
            "Click to reset all profiles to those stored in global_filter_profiles"
          }
          onClick={() => resetToGlobalProfiles()}
        >
          Reset
        </button>
        <button
          className={"std-button-class"}
          title={
            "Click to save the state to current profile, new text means new profile."
          }
          onClick={() => saveProfile()}
        >
          Save
        </button>
        <button
          className={"std-button-class"}
          title={"Deletes selected profile permanently."}
          onClick={() => {
            if (
              window.confirm(
                `Are you sure you wish to delete ${selectedProfile}?`,
              )
            )
              deleteProfile();
          }}
        >
          Delete
        </button>
        <input
          className={"cursor-input"}
          placeholder={"profile name"}
          value={textInput}
          maxLength={15}
          type={"text"}
          onChange={(event) => setTextInput(event.target.value)}
        />
      </div>
    );
  };

  const ProfileSelector = () => {
    return (
      <div className={"profile-selection-holder"}>
        <select
          className={"profile-selection-holder"}
          value={selectedProfile}
          onChange={(e) => {
            setSelectedProfile(e.target.value);
          }}
        >
          <option>{NO_PROFILE}</option>
          {Object.entries(filterProfiles).map(([key]) => (
            <option
              key={key}
              value={key}
              className={"profile-selection-holder"}
            >
              {key}
            </option>
          ))}
        </select>
        <div className={"Warning"}>
          {changesDetected ? <>* Unsaved Changes</> : <></>}
        </div>
      </div>
    );
  };

  return (
    <div>
      {settingsOpen ? (
        <div className={"profile-manager"}>
          <header>Profile Manager</header>
          {editMenu()}
          <br></br>
          <ProfileSelector />
        </div>
      ) : (
        <div className={"semi-transparent mr-1"}>
          <div className={"profile-icon"}>
            <CgProfile size={40} />
          </div>
          Profile
          <ProfileSelector />
        </div>
      )}
    </div>
  );
};
export default FilterProfiles;
