import React, { FC, useEffect, useState } from "react";
import { get_metrics_graph } from "../../../Helpers/get_data";
import { FiLoader } from "react-icons/fi";
import { IoIosTimer } from "react-icons/io";
import PossibleMetricsInterface from "../../../Interfaces/PossibleMetricsInterface";

const NodeMetricsButton: FC<{
  type: string;
  data: any;
  metricsToUse: PossibleMetricsInterface[];
}> = ({ type, data, metricsToUse }) => {
  const [nodeMetricsGraph, setNodeMetricsGraph] = useState("");
  const [payloadImg, setPayloadImg] = useState("");
  const [waitingForGraph, setWaitingForGraph] = useState(false);
  const handleGraphPopup = () => {
    window.dispatchEvent(new Event("node_metric_graph_open"));
  };

  useEffect(() => {
    setNodeMetricsGraph(payloadImg);
    localStorage.setItem("node_metric_graph", nodeMetricsGraph);
    localStorage.setItem("node_graph_type", type);
    localStorage.setItem(
      "node_open",
      JSON.stringify({ env: data.environment, var: data.variant }),
    );
    setPayloadImg("");
    setWaitingForGraph(false);
    handleGraphPopup();
  }, [payloadImg]);
  const metrics_requested = metricsToUse.find((e) => e.item_type === type);
  const metrics: string[] = metrics_requested
    ? metrics_requested.possible_metrics
    : [];
  return (
    <>
      {waitingForGraph ? (
        <button
          title={"Loading metrics..."}
          // className={"abs metric-footer "}
          className={"workers-button hide-workers"}
        >
          <FiLoader size={"1.5rem"} />
        </button>
      ) : (
        <button
          title={"Open metrics for this kind of node"}
          onClick={async () => {
            localStorage.setItem("node_metric_graph_open", "true");
            setWaitingForGraph(true);
            const aux_str = await get_metrics_graph(
              metrics,
              [data.variant],
              [data.environment],
            );
            setPayloadImg(aux_str);
          }}
          className={"workers-button"}
          // className={"abs bottom metric-footer effect1 effect2"}
        >
          <IoIosTimer size={"1.5rem"} />
        </button>
      )}
    </>
  );
};
export default NodeMetricsButton;
// export default NodeMetricHeader;
