import React, { useEffect, useState } from "react";
import Host from "../../Interfaces/HostInterface";
import Build from "../../Interfaces/BuildInterface";
import LoadingComponent from "../LoadingComponent";
import "react-notifications/lib/notifications.css";
import { useNodesState } from "reactflow";
import { NotificationContainer } from "react-notifications";
import FilterTab from "./FilterTab";
import RefreshIcon from "./RefreshIcon";
import CompleteInfo from "./CompleteInfo";
import { get_catalog_data, get_possible_metrics } from "../../Helpers/get_data";
import ImageGroup from "../../Interfaces/ImageGroupInterface";
import CatalogImagesDiagram from "./CatalogImagesDiagram";
import AllCatalogInterface from "../../Interfaces/AllCatalogInterface";
import NodeMetricGraph from "./NodeMetricGraph";
import FilterProfileDict from "../../Interfaces/FilterProfileDict";
import global_profiles_data from "../../Configuration/global_filter_profiles.json";
import FilterProfiles from "./FilterProfiles";
import PossibleMetricsInterface from "../../Interfaces/PossibleMetricsInterface";
import Relationship from "../../Interfaces/RelationshipInterface";
import FilterProfile from "../../Interfaces/FilterProfile";
import ErrorComponent from "../ErrorComponent";
import { NO_PROFILE } from "../../Configuration/Constants";
import SelectedRelationship from "../../Interfaces/SelectedRelationshipInterface";

function CatalogPage() {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [catalogPageRendered, setCatalogPageRendered] =
    useState<boolean>(false);
  // const [nodes, setNodes] = useState<Node[]>([]);
  const [buildsVisible, setBuildsVisible] = useState<boolean>(true);
  const [find_orphans, setFindOrphans] = useState<boolean>(true);

  const [infoData, setInfoData] = useState<Host | Build | null>(null);
  const [imageGroups, setImageGroups] = useState<ImageGroup[]>([]);
  const [orphanHosts, setOrphanHosts] = useState<Host[] | never[]>([]);
  const [orphanBuilds, setOrphanBuilds] = useState<Build[] | never[]>([]);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [diffFromProfile, setDiffFromProfile] = useState(new Set<string>());
  // All possible filters
  const [allVariantFilters, setAllVariantFilters] = useState<string[]>([]);
  const [allAppFilters, setAllAppFilters] = useState<string[]>([]);
  const [allSysNameFilters, setAllSysNameFilters] = useState<string[]>([]);
  const [allEnvFilters, setAllEnvFilters] = useState<string[]>([]);
  const [allItemTypeFilters, setAllItemTypeFilters] = useState<string[]>([]);
  // Selected filters
  const [filterProfiles, setFilterProfiles] = useState<FilterProfileDict>({});
  const [variantFilters, setVariantFilters] = useState<string[]>([]);
  const [appFilters, setAppFilters] = useState<string[]>([]);
  const [sysNameFilters, setSysNameFilters] = useState<string[]>([]);
  const [itemTypeFilters, setItemTypeFilters] = useState<string[]>([]);
  const [envFilters, setEnvFilters] = useState<string[]>([]);
  // Metrics
  const [metrics, setMetrics] = useState<string[]>([]);
  const [metricGraph, setMetricGraph] = useState("");
  const [metricsToUse, setMetricsToUse] = useState<PossibleMetricsInterface[]>(
    [],
  );

  const [selectedProfile, setSelectedProfile] = useState<string>(() => {
    return (
      new URLSearchParams(window.location.search).get("profile") ||
      "PROD-IR EDGE RT Classifiers"
    );
  });
  // Relationships
  const [selectedRelationship, setSelectedRelationship] =
    useState<SelectedRelationship | null>(null);

  const [infoPosition, setInfoPosition] = useState({ x: 0, y: 0 });

  const from_localstorage_to_state = (
    strg_name: string,
    setAllFilters: any,
  ) => {
    const all_filters = localStorage.getItem(strg_name);
    if (all_filters) setAllFilters(JSON.parse(all_filters));
  };

  useEffect(() => {
    // When rendering this component for the first time,
    const getAllPossibleFiltersFromLocalStorage = () => {
      from_localstorage_to_state("all_system_filters", setAllSysNameFilters);
      from_localstorage_to_state("all_environment_filters", setAllEnvFilters);
      from_localstorage_to_state("all_variant_filters", setAllVariantFilters);
      from_localstorage_to_state("all_application_filters", setAllAppFilters);
      from_localstorage_to_state(
        "all_item_type_filters",
        setAllItemTypeFilters,
      );
    };

    const add_new_global_profiles = () => {
      const global_profiles: FilterProfileDict = global_profiles_data;
      const filterProfiles = localStorage.getItem("filter_profiles");
      if (!filterProfiles) {
        setFilterProfiles(global_profiles);
        localStorage.setItem(
          "filter_profiles",
          JSON.stringify(global_profiles),
        );
      } else {
        const new_filter_profiles = JSON.parse(filterProfiles);
        Object.entries(global_profiles).forEach(([profile, profile_data]) => {
          if (!(profile in new_filter_profiles)) {
            new_filter_profiles[profile] = global_profiles[profile];
          }
        });
        localStorage.setItem(
          "filter_profiles",
          JSON.stringify(global_profiles),
        );
        setFilterProfiles(new_filter_profiles);
      }
    };

    // we will get all possible filters from previous sessions
    getAllPossibleFiltersFromLocalStorage();

    // we will add new profiles (if there are any new)
    add_new_global_profiles();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [nodes]);
  // When we have clicked on a profile to change it, it will get the filters from the profile
  useEffect(() => {
    // update filters depending on profile
    const select_profile_filters = () => {
      if (Object.keys(filterProfiles).length > 0) {
        if (selectedProfile === NO_PROFILE) {
          from_localstorage_to_state("filtered_item_types", setItemTypeFilters);
          from_localstorage_to_state("filtered_environments", setEnvFilters);
          from_localstorage_to_state("filtered_variants", setVariantFilters);
          from_localstorage_to_state("filtered_applications", setAppFilters);
          from_localstorage_to_state("filtered_systems", setSysNameFilters);
          from_localstorage_to_state("show_orphans", setFindOrphans);
          from_localstorage_to_state("show_builds", setBuildsVisible);
        } else {
          let profile: FilterProfile = filterProfiles[selectedProfile];
          if (profile) {
            setFindOrphans(false);
            setBuildsVisible(true);
            setAppFilters(profile.appFilters);
            setEnvFilters(profile.envFilters);
            setVariantFilters(profile.variantFilters);
            setSysNameFilters(profile.sysNameFilters);
            setItemTypeFilters(profile.itemTypeFilters);
          } else {
          }
        }
      }
    };

    select_profile_filters();
  }, [filterProfiles, selectedProfile]);

  // When there is a change on any of the filters, it will unselect any parent
  // This is to avoid showing children when we change a filter that hides the parent
  useEffect(() => {
    if (selectedRelationship !== null) {
      setSelectedRelationship(null);
    }
  }, [appFilters, variantFilters, sysNameFilters, envFilters, itemTypeFilters]);

  // When the imageGroups are fetched, we will get the data from the localstorage
  useEffect(() => {
    if (imageGroups.length > 0) {
      const get_localstorage = (values_to_store = "") => {
        const metricGraph = localStorage.getItem("metric_graph");
        if (metricGraph) setMetricGraph(JSON.parse(metricGraph));

        const metrics = localStorage.getItem("filtered_metrics");
        if (metrics) setMetrics(JSON.parse(metrics));

        // const builds_visible_data = localStorage.getItem("builds_visible");
        // if (builds_visible_data)
        //   setBuildsVisible(builds_visible_data === "true");
        // else localStorage.setItem("builds_visible", "true");
      };

      get_localstorage();
      // Here we conclude that the page has been rendered
      setCatalogPageRendered(true);
    }
  }, [imageGroups]);

  // When refreshing is true (initialization or clicking reload button):
  // we get the data from the backend and set the possible filters
  useEffect(() => {
    if (refreshing) {
      (async () => {
        const set_all_possible_filters = (all_data: any) => {
          const set_localstorage_and_state = (
            strg_name: string,
            value: any,
            setFilters: any,
          ) => {
            setFilters(value);
            localStorage.setItem(strg_name, JSON.stringify(value));
          };

          set_localstorage_and_state(
            "all_variant_filters",
            all_data.filters.variant,
            setAllVariantFilters,
          );
          set_localstorage_and_state(
            "all_application_filters",
            all_data.filters.application,
            setAllAppFilters,
          );
          set_localstorage_and_state(
            "all_system_filters",
            all_data.filters.system,
            setAllSysNameFilters,
          );
          set_localstorage_and_state(
            "all_environment_filters",
            all_data.filters.environment,
            setAllEnvFilters,
          );
          set_localstorage_and_state(
            "all_item_type_filters",
            all_data.filters.item_type,
            setAllItemTypeFilters,
          );
        };
        const all_data: AllCatalogInterface | { error: string } =
          await get_catalog_data();
        if ("error" in all_data) {
          setErrorMessage(all_data.error);
          setRefreshing(false);
          return;
        }
        const possible_metrics = await get_possible_metrics();

        if (!possible_metrics) {
          console.log("ERROR: Couldn't fetch possible metrics.");
        } else {
          setMetricsToUse(possible_metrics);
        }

        setErrorMessage("");
        const images: ImageGroup[] = all_data.images;
        setImageGroups(images);

        const orphan_hosts = all_data.orphan_hosts;
        setOrphanHosts(orphan_hosts);

        const orphan_builds = all_data.orphan_builds;
        setOrphanBuilds(orphan_builds);

        set_all_possible_filters(all_data);
        setRefreshing(false);
      })();
    }
  }, [refreshing]);

  // To do: To clean a little bit the props, we could have a variable that stores the filters, other that stores the setFilters. Look at selected_filters below
  // Create Contexts to avoid passing so many props
  // Use components as {children} of other components to avoid passing props

  return (
    <div
      className={"full-view"}
      style={{ width: window.innerWidth, height: window.innerHeight }}
    >
      <div className={"right0 abs"}>
        <FilterProfiles
          filterProfiles={filterProfiles}
          setFilterProfiles={setFilterProfiles}
          sysNameFilters={sysNameFilters}
          appFilters={appFilters}
          variantFilters={variantFilters}
          envFilters={envFilters}
          itemTypeFilters={itemTypeFilters}
          setSelectedProfile={setSelectedProfile}
          selectedProfile={selectedProfile}
          settingsOpen={settingsOpen}
          setDiffFromProfile={setDiffFromProfile}
        />
      </div>
      <div className={"flexbox"}>
        <FilterTab
          setBuildsVisible={setBuildsVisible}
          buildsVisible={buildsVisible}
          allAppFilters={allAppFilters}
          allVariantFilters={allVariantFilters}
          allSysNameFilters={allSysNameFilters}
          allEnvFilters={allEnvFilters}
          allItemTypeFilters={allItemTypeFilters}
          selected_filters={{
            appFilters,
            variantFilters,
            sysNameFilters,
            envFilters,
            itemTypeFilters,
          }}
          setAppFilters={setAppFilters}
          setVariantFilters={setVariantFilters}
          setSysNameFilters={setSysNameFilters}
          setEnvFilters={setEnvFilters}
          setItemTypeFilters={setItemTypeFilters}
          setFindOrphans={setFindOrphans}
          find_orphans={find_orphans}
          nodes={nodes}
          metrics={metrics}
          setMetrics={setMetrics}
          metricsToUse={metricsToUse}
          metricGraph={metricGraph}
          setMetricGraph={setMetricGraph}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          diffFromProfile={diffFromProfile}
          selectedProfile={selectedProfile}
        />

        <NodeMetricGraph metricsToUse={metricsToUse} />
        <RefreshIcon
          refreshing={refreshing}
          setRefreshing={setRefreshing}
          setErrorMessage={setErrorMessage}
        />
      </div>

      <CompleteInfo infoData={infoData} setInfoData={setInfoData} />
      {errorMessage === "" ? (
        <>
          {catalogPageRendered ? (
            <CatalogImagesDiagram
              buildsVisible={buildsVisible}
              variantFilters={variantFilters}
              appFilters={appFilters}
              sysNameFilters={sysNameFilters}
              envFilters={envFilters}
              itemTypeFilters={itemTypeFilters}
              setInfoData={setInfoData}
              infoData={infoData}
              imageGroups={imageGroups}
              orphan_hosts={orphanHosts}
              orphan_builds={orphanBuilds}
              find_orphans={find_orphans}
              setNodes={setNodes}
              nodes={nodes}
              metricsToUse={metricsToUse}
              setSelectedRelationship={setSelectedRelationship}
              selectedRelationship={selectedRelationship}
              setInfoPosition={setInfoPosition}
            />
          ) : (
            <LoadingComponent />
          )}
        </>
      ) : (
        <ErrorComponent errorMessage={errorMessage} />
      )}

      <NotificationContainer />
    </div>
  );
}

export default CatalogPage;
