import Relationship from "../Interfaces/RelationshipInterface";

const relationships: Record<string, Relationship> = {
  "class-distributor": {
    itemType: "rt-host",
    property: "variant",
    parent: "class-distributor",
    children: "class-worker",
    type: "host",
  },
  "distributor-unknown": {
    itemType: "rt-host",
    property: "variant",
    parent: "class-dist-oversized",
    children: "class-worker-oversized",
    type: "host",
  },
  "base-mc-dev": {
    itemType: "build",
    property: "application",
    parent: "dms-base",
    children: "management-console",
    environment: "development",
    type: "build",
  },
  "base-mc-marbella": {
    itemType: "build",
    property: "application",
    parent: "dms-base",
    children: "management-console",
    environment: "marbella",
    type: "build",
  },
};

export default relationships;
