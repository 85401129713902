import React, {FC} from "react";

const NodeName: FC<{
    environment: string;
    nodeId: string;
    variant: string;
}> = ({environment, nodeId, variant}) => {
    return (
        <div
            className={"ellipsis"}
            style={{
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "center",
                // alignItems: "center",
                // paddingRight: "10px",
                // paddingLeft: "10px",
                fontSize: "1rem",
                // maxWidth: "100%",
                fontWeight: "bold",
            }}
            title={environment + "-" + variant}
        >
            {environment}-{variant}
        </div>
    );
};

export default NodeName;
