import React, { FC } from "react";

import NodeMetricsButton from "./NodeComponents/NodeMetricsButton";
import InfoButton from "./NodeComponents/InfoButton";
import ChildrenButton from "./NodeComponents/ChildrenButton";
import HostNodeInterface from "../../Interfaces/HostNodeInterface";
import BuildNodeInterface from "../../Interfaces/BuildNodeInterface";
import { isHostNodeInterface } from "../../Helpers/interface_helpers";

const ButtonSection: FC<{
  data: HostNodeInterface | BuildNodeInterface;
}> = React.memo(({ data }) => {
  const is_host = isHostNodeInterface(data);
  return (
    <div
      className={"button-section"}
      style={{ justifyContent: "" + (is_host ? "flex-start" : "flex-end") }}
    >
      <div
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        {is_host ? (
          <ChildrenButton
            data={data.data}
            setSelectedRelationship={data.setSelectedRelationship}
            isSelected={data.isSelected}
            canBeParent={data.canBeParent}
          />
        ) : (
          <div></div>
        )}
        <InfoButton data={data} />
        <NodeMetricsButton
          type={data.data.itemType}
          data={data.data}
          metricsToUse={data.metricsToUse}
        />
      </div>
    </div>
  );
});

export default ButtonSection;
