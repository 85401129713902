import React from "react";
import "./App.css";
import CatalogPage from "./Components/MainComponents/CatalogPage";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// dummy change
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<CatalogPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
