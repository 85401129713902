import React, {FC} from "react";
import Host from "../../../Interfaces/HostInterface";
import {FaInfoCircle} from "react-icons/fa";
import Build from "../../../Interfaces/BuildInterface";

const InfoButton: FC<{
    data: {
        data: Host | Build;
        setInfoData: any;
        node_selected: boolean;
        setInfoPosition: any;
        isChild?: boolean;
    };
}> = React.memo(({data}) => {
    return (
        <>
            {data.node_selected ? (
                <button
                    onClick={() => {
                        data.setInfoData(null);
                    }}
                    className={"workers-button hide-workers"}
                    title={"Close node info"}
                >
                    <FaInfoCircle size={"1.5rem"} color={"white"}/>
                </button>
            ) : (
                <button
                    onClick={(event) => {
                        data.setInfoData(data.data);
                        const x = event.clientX; // Adjusting to center the element
                        const y = event.clientY; // Adjusting to center the element
                        if (data.isChild) {
                            data.setInfoPosition({x: x - 0.34 * window.innerWidth, y});
                        } else {
                            data.setInfoPosition({x, y});
                        }
                    }}
                    className={"workers-button"}
                    title={"Get info on this node"}
                >
                    <FaInfoCircle size={"1.5rem"}/>
                </button>
            )}
        </>
    );
});

export default InfoButton;
