import React, { FC, useEffect } from "react";
import "react-notifications/lib/notifications.css";
import { IoIosCloseCircle, IoMdSettings } from "react-icons/io";
import FilterSection from "../FilterSection";
import MetricsGraph from "./MetricsGraph";
import PossibleMetricsInterface from "../../Interfaces/PossibleMetricsInterface";
import Filters from "../../Interfaces/SelectedFilters";
import { NO_PROFILE } from "../../Configuration/Constants";

const FilterTab: FC<{
  selectedProfile: string | undefined;
  setBuildsVisible: any;
  buildsVisible: boolean;
  allAppFilters: any;
  allVariantFilters: any;
  allSysNameFilters: any;
  allEnvFilters: any;
  allItemTypeFilters: any;
  setVariantFilters: any;
  setAppFilters: any;
  setSysNameFilters: any;
  setEnvFilters: any;
  setFindOrphans: any;
  find_orphans: boolean;
  setItemTypeFilters: any;
  nodes: any;
  metrics: string[];
  setMetrics: any;
  metricGraph: string;
  setMetricGraph: any;
  metricsToUse: PossibleMetricsInterface[];
  settingsOpen: boolean;
  setSettingsOpen: any;
  diffFromProfile: Set<string>;
  selected_filters: Filters;
}> = ({
  selectedProfile,
  setBuildsVisible,
  buildsVisible,
  allVariantFilters,
  allEnvFilters,
  allAppFilters,
  allSysNameFilters,
  allItemTypeFilters,
  setVariantFilters,
  setEnvFilters,
  setAppFilters,
  setSysNameFilters,
  setFindOrphans,
  find_orphans,
  setItemTypeFilters,
  nodes,
  metrics,
  setMetrics,
  metricGraph,
  setMetricGraph,
  metricsToUse,
  settingsOpen,
  setSettingsOpen,
  diffFromProfile,
  selected_filters,
}) => {
  useEffect(() => {
    const settings_open = localStorage.getItem("settings_open");
    if (settings_open != null) {
      if (settings_open === "true") {
        setSettingsOpen(true);
      } else {
        setSettingsOpen(false);
      }
    }
  }, []);

  const selectVariantFilters = (newFilter: string) => {
    selectFilters(
      newFilter,
      selected_filters.variantFilters,
      setVariantFilters,
      "filtered_variants",
    );
  };
  const selectAppFilters = (newFilter: string) => {
    selectFilters(
      newFilter,
      selected_filters.appFilters,
      setAppFilters,
      "filtered_applications",
    );
  };

  const selectSysNameFilters = (newFilter: string) => {
    selectFilters(
      newFilter,
      selected_filters.sysNameFilters,
      setSysNameFilters,
      "filtered_systems",
    );
  };

  const selectEnvFilters = (newFilter: string) => {
    selectFilters(
      newFilter,
      selected_filters.envFilters,
      setEnvFilters,
      "filtered_environments",
    );
  };
  const selectItemTypeFilters = (newFilter: string) => {
    selectFilters(
      newFilter,
      selected_filters.itemTypeFilters,
      setItemTypeFilters,
      "filtered_item_types",
    );
  };
  const selectAllEnv = () => {
    selectAllFilters(
      allEnvFilters,
      selected_filters.envFilters,
      setEnvFilters,
      "filtered_environments",
    );
  };
  const selectAllSysName = () => {
    selectAllFilters(
      allSysNameFilters,
      selected_filters.sysNameFilters,
      setSysNameFilters,
      "filtered_systems",
    );
  };
  const selectAllVariant = () => {
    selectAllFilters(
      allVariantFilters,
      selected_filters.variantFilters,
      setVariantFilters,
      "filtered_variants",
    );
  };
  const selectAllApp = () => {
    selectAllFilters(
      allAppFilters,
      selected_filters.appFilters,
      setAppFilters,
      "filtered_applications",
    );
  };
  const selectAllHostType = () => {
    selectAllFilters(
      allItemTypeFilters,
      selected_filters.itemTypeFilters,
      setItemTypeFilters,
      "filtered_item_types",
    );
  };
  const selectAllFilters = (
    possible_filters: string[],
    filters: string[],
    setFilters: (arg0: string[]) => void,
    local_name = "filtered_variants",
  ) => {
    if (filters.length === possible_filters.length) {
      setFilters([]);
      if (selectedProfile === NO_PROFILE) {
        localStorage.setItem(local_name, JSON.stringify([]));
      }
    } else {
      setFilters(possible_filters);
      if (selectedProfile === NO_PROFILE) {
        localStorage.setItem(local_name, JSON.stringify(possible_filters));
      }
    }
  };

  const selectFilters = (
    newFilter: string,
    filters: string[],
    setFilters: (arg0: string[]) => void,
    local_name = "filtered_variants",
  ) => {
    if (filters.includes(newFilter)) {
      const selected_filters = filters.filter((e: string) => e !== newFilter);
      setFilters(selected_filters);
      if (selectedProfile === NO_PROFILE) {
        localStorage.setItem(local_name, JSON.stringify(selected_filters));
      }
    } else {
      const selected_filters = [...filters, newFilter];
      setFilters(selected_filters);
      if (selectedProfile === NO_PROFILE) {
        localStorage.setItem(local_name, JSON.stringify(selected_filters));
      }
    }
  };

  const SettingsIcon = () => {
    return (
      <div className="settings">
        <button
          className={"wheel-container"}
          onClick={() => {
            setSettingsOpen(true);
            localStorage.setItem("settings_open", "true");
          }}
        >
          <IoMdSettings className={"settings-icon"} size={50} />
        </button>
        <div className={"hide-settings abs"} style={{ fontWeight: 900 }}>
          Settings
        </div>
      </div>
    );
  };

  const select_show_builds = () => {
    setBuildsVisible(!buildsVisible);
    if (selectedProfile === NO_PROFILE) {
      localStorage.setItem("show_builds", !buildsVisible ? "true" : "false");
    }
  };

  const select_show_orphans = () => {
    setFindOrphans(!find_orphans);
    if (selectedProfile === NO_PROFILE) {
      localStorage.setItem("show_orphans", !find_orphans ? "true" : "false");
    }
  };

  return (
    <div className={""}>
      <div className={settingsOpen ? "" : "hide"} style={{}}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            backgroundColor: "lightgrey",
            borderLeftWidth: 0,
            borderTopWidth: 0,
            borderRightWidth: 1,
            borderBottomWidth: 1,
            borderColor: "black",
            borderStyle: "solid",
            margin: 0,
            borderBottomRightRadius: 50,
            zIndex: 2,
            textWrap: "nowrap",
          }}
        >
          <div>
            <div
              onClick={() => select_show_builds()}
              className={"pointer"}
              style={{ padding: 3 }}
            >
              <input
                type="checkbox"
                id="show-builds"
                checked={buildsVisible}
                onChange={() => select_show_builds()}
              />
              <label htmlFor="show-builds">Show builds</label>
            </div>
            <div className={"pointer"} style={{ padding: 5 }}>
              <input
                id="show-orphans"
                type="checkbox"
                checked={find_orphans}
                onChange={() => {
                  select_show_orphans();
                }}
              />
              <label htmlFor="show-orphans">Show orphans</label>
            </div>
            <div className={"abs aggregate-metric-footer "}>
              <MetricsGraph
                nodes={nodes}
                metrics={metrics}
                setMetrics={setMetrics}
                metricGraph={metricGraph}
                setMetricGraph={setMetricGraph}
                metricsToUse={metricsToUse}
                diffFromProfile={diffFromProfile}
              />
            </div>
          </div>

          <FilterSection
            filters={selected_filters.sysNameFilters}
            possibleFilters={allSysNameFilters}
            selectFilters={selectSysNameFilters}
            selectAll={selectAllSysName}
            title={"SystemName"}
            diffFromProfile={diffFromProfile}
          />
          <FilterSection
            filters={selected_filters.envFilters}
            possibleFilters={allEnvFilters}
            selectFilters={selectEnvFilters}
            selectAll={selectAllEnv}
            title={"Environment"}
            diffFromProfile={diffFromProfile}
          />
          <FilterSection
            filters={selected_filters.variantFilters}
            possibleFilters={allVariantFilters}
            selectFilters={selectVariantFilters}
            selectAll={selectAllVariant}
            title={"Variant"}
            diffFromProfile={diffFromProfile}
          />
          <FilterSection
            filters={selected_filters.appFilters}
            possibleFilters={allAppFilters}
            selectFilters={selectAppFilters}
            selectAll={selectAllApp}
            title={"Application"}
            diffFromProfile={diffFromProfile}
          />
          <FilterSection
            filters={selected_filters.itemTypeFilters}
            possibleFilters={allItemTypeFilters}
            selectFilters={selectItemTypeFilters}
            selectAll={selectAllHostType}
            title={"Item Type"}
            diffFromProfile={diffFromProfile}
          />

          <IoIosCloseCircle
            className={"close-container"}
            size={"2rem"}
            title={"Close settings"}
            onClick={() => {
              setSettingsOpen(false);
              localStorage.setItem("settings_open", !settingsOpen + "");
            }}
          />
        </div>
      </div>
      {!settingsOpen ? (
        <div className={"opening-filters"}>
          <SettingsIcon />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FilterTab;
