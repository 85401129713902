import {Handle, Position} from "reactflow";
import React, {FC} from "react";
import IsTheLastImage from "./IsTheLastImage";
import {FaFile} from "react-icons/fa";
import {get_host_color_from_status} from "../../Helpers/helpers";

// const ImageNode = ({ data }) => {
const ImageNode: FC<{
    data: any;
}> = ({data}) => {
    const innerWidth = window.innerWidth;
    const {backgroundColor, fontColor} = get_host_color_from_status(
        "SUCCESS",
        data.last_image,
        data.older_than_24h,
    );
    return (
        <div
            className="node"
            style={{
                width: innerWidth * 0.2 + "px",
                borderRadius: "5vw",
                backgroundColor: backgroundColor,
                color: fontColor,
                justifyContent: "center",
            }}
        >
            <IsTheLastImage
                last_image={data.last_image}
                is_node={false}
                show_older_than_24h={data.older_than_24h}
                missing_parent={data.missing_parent}
            />
            <div
                style={{
                    maxWidth: "80%",
                    fontSize: "0.8rem",
                    paddingTop: 10,
                }}
            >
                {data.label}
            </div>

            <div
                className={"abs node-footer "}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    borderTopRightRadius: "1rem",
                    borderTopLeftRadius: "1rem",
                }}
            >
                Image
                <FaFile size={"1rem"}/>
            </div>
            {data.last_image ? (
                <Handle
                    type="target"
                    position={Position.Left}
                    style={{background: "#555"}}
                    isConnectable={false}
                />
            ) : (
                <></>
            )}
            <Handle
                type="source"
                position={Position.Right}
                style={{background: "#555"}}
                isConnectable={false}
            />
        </div>
    );
};

export default ImageNode;
