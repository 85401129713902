import Host from "../Interfaces/HostInterface";
import Build from "../Interfaces/BuildInterface";
import { NotificationManager } from "react-notifications";

export const get_build_ts_from_status = (data: Build) => {
  let last_ts = "";
  switch (data.status + "") {
    case `STARTED`:
      if ("buildStartTs" in data) {
        last_ts = data.buildStartTs;
      }
      // if (data instanceof Build){
      if ("buildStartTs" in data) {
      }
      break;
    case "SUCCESS":
      last_ts = data.buildEndTs;
      break;
    default:
      last_ts = data.statusTs;
      break;
  }
  return last_ts;
};
export const get_host_ts_from_status = (data: Host) => {
  let last_ts = "";
  switch (data.status + "") {
    case "Running":
      last_ts = data.runningTs ? data.runningTs : "";
      break;
    default:
      last_ts = data.statusTs;
      break;
  }
  return last_ts;
};

export const get_host_color_from_status = (
  status: string,
  last_image: boolean = true,
  older_than_24h: boolean = false,
) => {
  if (older_than_24h && status !== "Terminated") {
    return { backgroundColor: "brown", fontColor: "white" };
  }
  if (!last_image && status !== "Terminated") {
    return { backgroundColor: "orange", fontColor: "white" };
  }
  switch (status) {
    case "SUCCESS":
      return { backgroundColor: "green", fontColor: "white" };
    case "Stopped":
      return { backgroundColor: "PaleGreen", fontColor: "black" };
    case "STARTED":
      return { backgroundColor: "rgba(0,255,50,0.8)", fontColor: "black" };
    case "Fetching":
      return { backgroundColor: "rgba(0,255,50,0.6)", fontColor: "black" };

    case "Starting":
      return { backgroundColor: "Coral", fontColor: "black" };
    case "Provisioning":
      return { backgroundColor: "rgba(0,255,50,0.2)", fontColor: "black" };
    case "Late":
      return { backgroundColor: "orange", fontColor: "white" };
    case "slightly_late":
      return { backgroundColor: "yellow", fontColor: "black" };
    case "running_old_image":
      return { backgroundColor: "darkgreen", fontColor: "white" };
    case "no_build_match":
      return { backgroundColor: "purple", fontColor: "white" };
    case "Downloading":
      return { backgroundColor: "rgba(0,255,50,0.2)", fontColor: "black" };
    case "Running":
      return { backgroundColor: "green", fontColor: "white" };
    case "Pending-restart":
      return { backgroundColor: "rgba(205,205,100,1)", fontColor: "black" };
    case "Terminated":
      return { backgroundColor: "rgba(255,100,155,0.8)", fontColor: "black" };
    default:
      return { backgroundColor: "red", fontColor: "white" };
  }
};

export const handleCopyToClipboard = (
  all_data: Host | Build | null,
  data: Host | Build | string | null = null,
) => {
  let data_to_copy: Host | Build | string | null = all_data;
  if (data) {
    data_to_copy = data;
  }
  if (navigator && navigator.clipboard) {
    navigator.clipboard
      .writeText(
        typeof data_to_copy === "string"
          ? data_to_copy
          : JSON.stringify(data_to_copy),
      )
      .then(() => {
        NotificationManager.success(
          JSON.stringify(data_to_copy).substr(0, 50) + "...",
          "Info copied!",
        );
      })
      .catch((error) => {
        NotificationManager.error(
          JSON.stringify(data_to_copy).substr(0, 50) + "...",
          "Failed to copy!",
        );
        console.log({ error });
      });
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = JSON.stringify(data_to_copy);
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    NotificationManager.success(
      JSON.stringify(all_data).substr(0, 50) + "...",
      "Info copied!",
    );
  }
};
