import {IoIosCloseCircle, IoIosTimer} from "react-icons/io";
import React, {FC, useEffect, useState} from "react";
import {Node} from "reactflow";
import {get_metrics_graph} from "../../Helpers/get_data";
import FilterSection from "../FilterSection";
import {FiLoader} from "react-icons/fi";
import {FaInfoCircle} from "react-icons/fa";
import PossibleMetricsInterface from "../../Interfaces/PossibleMetricsInterface";

const MetricsGraph: FC<{
    nodes: Node[];
    metrics: string[];
    setMetrics: any;
    metricGraph: any;
    setMetricGraph: any;
    metricsToUse: PossibleMetricsInterface[];
    diffFromProfile: Set<string>;
}> = ({
          nodes,
          metrics,
          setMetrics,
          metricGraph,
          setMetricGraph,
          metricsToUse,
          diffFromProfile,
      }) => {
    const [metricsGraphsOpen, setMetricsGraphsOpen] = useState<boolean>(false);
    const [metricVariants, setMetricVariants] = useState(new Set<string>());
    const [metricEnvironments, setMetricEnvironments] = useState(
        new Set<string>(),
    );
    const [waitingForGraph, setWaitingForGraph] = useState(false);
    const [possibleMetrics, setPossibleMetrics] = useState<string[]>([]);
    const [days, setDays] = useState("7");
    useEffect(() => {
        let aggregatedMetrics: string[] = [];
        metricsToUse.forEach((e) => {
            aggregatedMetrics = aggregatedMetrics.concat(e.possible_metrics);
        });
        let aux_array: string[] = [];
        aggregatedMetrics.forEach((e) => {
            aux_array.push(e + "_broad");
        });
        setPossibleMetrics(Array.from<string>(new Set<string>(aux_array)));
    }, [metricsToUse]);

    useEffect(() => {
        if (metricsGraphsOpen) {
            setNodeVarsEnvs();
            fetchGraphs();
        }
    }, [metricsGraphsOpen, nodes]);
    useEffect(() => {
        (async () => {
            if (metricsGraphsOpen && nodes.length > 0) {
                await fetchGraphs();
            }
        })();
    }, [metricVariants, metricEnvironments, metrics, days]);
    const setNodeVarsEnvs = () => {
        const aux_vars_set = new Set<string>();
        const aux_envs_set = new Set<string>();
        nodes.forEach((node) => {
            if (node.data.data && node.data.data.variant) {
                aux_vars_set.add(node.data.data.variant);
            }
            if (node.data.data && node.data.data.environment) {
                aux_envs_set.add(node.data.data.environment);
            }
        });
        setMetricVariants(aux_vars_set);
        setMetricEnvironments(aux_envs_set);
    };
    const fetchGraphs = async () => {
        setWaitingForGraph(true);
        setMetricGraph(
            await get_metrics_graph(
                metrics,
                Array.from(metricVariants),
                Array.from(metricEnvironments),
                days,
            ),
        );
        localStorage.setItem("metric_graph", JSON.stringify(metricGraph));
        setWaitingForGraph(false);
    };

    const selectMetricFilters = (newFilter: string) => {
        selectFilters(newFilter, metrics, setMetrics, "filtered_metrics");
    };
    const selectFilters = (
        newFilter: string,
        filters: string[],
        setFilters: (arg0: string[]) => void,
        local_name = "filtered_metrics",
    ) => {
        if (filters.includes(newFilter)) {
            const selected_filters = filters.filter((e: string) => e !== newFilter);
            setFilters(selected_filters);
            localStorage.setItem(local_name, JSON.stringify(selected_filters));
        } else {
            const selected_filters = [...filters, newFilter];
            setFilters(selected_filters);
            localStorage.setItem(local_name, JSON.stringify(selected_filters));
        }
    };
    const selectAllMetrics = () => {
        selectAllFilters(possibleMetrics, metrics, setMetrics, "filtered_metrics");
    };
    const selectAllFilters = (
        possible_filters: string[],
        filters: string[],
        setFilters: (arg0: string[]) => void,
        local_name = "filtered_variants",
    ) => {
        if (filters.length === possible_filters.length) {
            setFilters([]);
            localStorage.setItem(local_name, JSON.stringify([]));
        } else {
            setFilters(possible_filters);
            localStorage.setItem(local_name, JSON.stringify(possible_filters));
        }
    };

    const MetricsGraphIcon = () => {
        return (
            <div className={"metrics"}>
                <button
                    className={"metrics-container"}
                    onClick={() => {
                        setMetricsGraphsOpen(true);
                        localStorage.setItem("metrics_open", "true");
                    }}
                >
                    <IoIosTimer className={"settings-icon"} size={50}/>
                </button>
                <div className={"hide-settings abs"} style={{fontWeight: 900}}>
                    Metrics
                </div>
            </div>
        );
    };

    const MetricsGraph = () => {
        return (
            <>
                <div>
                    {waitingForGraph ? (
                        <FiLoader size={"1.5rem"} className={"overlay-loader"}/>
                    ) : (
                        <></>
                    )}
                    {nodes.length === 0 || !metricGraph ? (
                        <div className={"graph-place-holder"}>
              <span className={"text"}>
                <FaInfoCircle size={24}/>
                No Metrics Selected or No Nodes Visible
              </span>
                        </div>
                    ) : (
                        <img
                            alt={
                                "Graph of the metrics for the environments and variants selected above."
                            }
                            src={`data:image/png;base64,${metricGraph}`}
                        />
                    )}
                </div>
            </>
        );
    };
    return (
        <div>
            {metricsGraphsOpen ? (
                <div className={"aggregate-metrics-block"}>
                    <div
                        style={{
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            Aggregate Metrics from all visible nodes.
                        </div>

                        <MetricsGraph/>
                    </div>
                    <div
                        style={{
                            flexDirection: "column",
                        }}
                    >
                        <FilterSection
                            filters={metrics}
                            possibleFilters={possibleMetrics}
                            selectFilters={selectMetricFilters}
                            selectAll={selectAllMetrics}
                            title={"Metrics"}
                            diffFromProfile={diffFromProfile}
                        />
                        <button
                            style={{
                                width: 160,
                            }}
                            onClick={() => {
                                fetchGraphs();
                            }}
                        >
                            Force Refresh Graph
                        </button>
                    </div>
                    <div className={"abs bottom10 right10"}>
                        Select Days:
                        <select
                            id={"how-far-back"}
                            onChange={(val) => setDays(val.target.value)}
                        >
                            <option value={7}>7</option>
                            <option value={30}>30</option>
                            <option value={90}>90</option>
                        </select>
                    </div>

                    <IoIosCloseCircle
                        className={"close-container"}
                        title={"Close metrics graph window"}
                        size={"2rem"}
                        onClick={() => {
                            setMetricsGraphsOpen(false);
                            localStorage.setItem("metrics_open", metricsGraphsOpen + "");
                        }}
                    />
                </div>
            ) : (
                <>
                    <MetricsGraphIcon/>
                </>
            )}
        </div>
    );
};
export default MetricsGraph;
