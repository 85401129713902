import {FC} from "react";
import {RxLapTimer} from "react-icons/rx";
import {MdBrokenImage} from "react-icons/md";
import {CgLastpass} from "react-icons/cg";

const IsTheLastImage: FC<{
    last_image: boolean;
    is_node: boolean;
    show_older_than_24h: boolean;
    missing_parent: boolean;
}> = ({last_image, is_node, show_older_than_24h, missing_parent}) => {
    return (
        <div style={{fontSize: "0.8rem"}}>
            {missing_parent ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <MdBrokenImage size={"1.5rem"}/>
                    Missing host parent
                </div>
            ) : (
                <></>
            )}
            {show_older_than_24h ? (
                <div
                    style={{
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <RxLapTimer size={"1.5rem"} style={{padding: "3px"}}/>
                    <div>The image is older than 24 h</div>
                </div>
            ) : (
                <></>
            )}

            <>
                {last_image ? (
                    <></>
                ) : (
                    <div
                        style={{
                            fontWeight: "bold",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <CgLastpass size={"1.5rem"} style={{padding: "3px"}}/>
                        <div>
                            It's not <>{is_node ? "using " : ""}</>the latest image!
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

export default IsTheLastImage;
