import StatusIcon from "./StatusIcon";
import React, {FC, useEffect, useState,} from "react";
import Build from "../../../Interfaces/BuildInterface";
import Host from "../../../Interfaces/HostInterface";
import TimeComponent from "./TimeComponent";
import {get_build_ts_from_status, get_host_ts_from_status, handleCopyToClipboard,} from "../../../Helpers/helpers";
import {MdBrokenImage} from "react-icons/md";
import {RxLapTimer} from "react-icons/rx";
import {CgLastpass} from "react-icons/cg";
import {RiFileCopyFill} from "react-icons/ri";

const NodeStatus: FC<{
    data: Host | Build;
    last_image: boolean;
    older_than_24h: boolean;
    missing_parent: boolean;
}> = ({data, last_image, older_than_24h, missing_parent}) => {
    const [ts, setTs] = useState("");

    useEffect(() => {
        function isHost(data: Host | Build): data is Host {
            return (data as Host).systemName !== undefined;
        }

        if (isHost(data)) {
            setTs(get_host_ts_from_status(data));
        } else {
            setTs(get_build_ts_from_status(data));
        }
    }, [data]);

    // type ParentProps = React.PropsWithChildren<{ text: string }>;
    interface ParentProps {
        text: string;
    }

    type Props = {
        text: string;
        children: JSX.Element;
    };

    // const StatusMessage: React.FC<PropsWithChildren<ParentProps>> = ({
    //   text,
    //   children: string,
    // }) => {

    const StatusMessage = ({text, children}: Props) => (
        <div title={text} className={"pointer status"}>
            {children ? <div style={{padding: "3px"}}>{children}</div> : <></>}

            <div
                style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    width: "100%",
                    textAlign: "start",
                    // justifyContent: "flex-start",
                    // backgroundColor: "orange",
                }}
            >
                {text}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
                onClick={() => {
                    handleCopyToClipboard(null, text);
                }}
                className={"pointer copy-status"}
                title={"Copy status"}
            >
                <RiFileCopyFill/>
            </div>
        </div>
    );

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                borderRadius: "10px",
                flex: 1,
                // width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                // width: "100%",
                padding: "5px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    fontSize: "0.8rem",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                        overflow: "hidden",
                    }}
                >
                    {missing_parent && data.status !== "Terminated" ? (
                        <StatusMessage text={"Missing host parent"}>
                            <MdBrokenImage size={"1.2rem"}/>
                        </StatusMessage>
                    ) : (
                        <></>
                    )}
                    {older_than_24h && data.status !== "Terminated" ? (
                        <StatusMessage text={"The image is older than 24h"}>
                            <RxLapTimer size={"1.2rem"}/>
                        </StatusMessage>
                    ) : (
                        <></>
                    )}
                    {!last_image && data.status !== "Terminated" ? (
                        <StatusMessage text={"It's not using the latest image!"}>
                            <CgLastpass size={"1.2rem"}/>
                        </StatusMessage>
                    ) : (
                        <></>
                    )}
                    <div className={"status"}>
                        <StatusIcon status={data.status}/>
                        <div style={{padding: "5px"}}>{data.status}</div>
                    </div>
                </div>
                <div
                    style={{
                        flex: 1,
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                    }}
                >
                    <TimeComponent
                        timestamp={ts}
                        label={data.status + " time"}
                        add_label={true}
                        row={false}
                    />
                </div>
            </div>
            {/*<IsTheLastImage*/}
            {/*  show_outdated_image={show_outdated_image}*/}
            {/*  is_node={true}*/}
            {/*  show_older_than_24h={false}*/}
            {/*  missing_parent={false}*/}
            {/*/>*/}
        </div>
    );
};

export default NodeStatus;
