import React, { FC, useState } from "react";
import "react-notifications/lib/notifications.css";
import FilterCheckbox from "../Components/FilterCheckbox";
import { FaChevronUp } from "react-icons/fa";

const FilterSection: FC<{
  possibleFilters: string[];
  filters: string[];
  selectFilters: any;
  title: string;
  selectAll: any;
  diffFromProfile: Set<string>;
}> = ({
  possibleFilters,
  filters,
  selectFilters,
  title,
  selectAll,
  diffFromProfile,
}) => {
  const [expanded, setExpanded] = useState(true);
  const flip = {
    transform: expanded ? "rotate(180deg)" : "",
    transition: "transform 150ms ease", // smooth transition
  };

  return (
    <div>
      <div
        className="std-button-class width12"
        onClick={() => setExpanded(!expanded)}
        title={(expanded ? "Collapse " : "Expand ") + title + " filters"}
      >
        <div>{title}</div>
        <div className="col-2 d-flex align-items-center">
          <small>
            <FaChevronUp style={flip} />
          </small>
        </div>
      </div>
      {expanded ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: 1,
          }}
        >
          {possibleFilters.map((e) => (
            <div key={"filter_" + title + e}>
              <FilterCheckbox
                env={e}
                filters={filters}
                selectFilters={selectFilters}
                diffFromProfile={diffFromProfile}
              />
            </div>
          ))}
          <div
            onClick={(e) => {
              e.stopPropagation();
              selectAll();
            }}
            className={"pointer"}
            style={{ borderTop: "1px solid black" }}
          >
            <input
              id={"select-all" + title}
              type="checkbox"
              onClick={(e) => e.stopPropagation()} // Prevent input click from triggering parent click
              checked={filters.length === possibleFilters.length}
            />
            <label htmlFor={"select-all" + title}>Select all ({title})</label>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterSection;
