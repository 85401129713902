import React, { FC, useEffect } from "react";
import Host from "../../../Interfaces/HostInterface";

import parent_child_relationships from "../../../Configuration/parent_child_relationships";
import Relationship from "../../../Interfaces/RelationshipInterface";
import { BsDiagram3, BsDiagram3Fill } from "react-icons/bs";
import SelectedRelationship from "../../../Interfaces/SelectedRelationshipInterface";

const ChildrenButton: FC<{
  data: Host;
  setSelectedRelationship: any;
  isSelected: boolean;
  canBeParent: boolean;
}> = React.memo(
  ({ data, setSelectedRelationship, isSelected, canBeParent }) => {
    const select_relationship = () => {
      Object.entries(parent_child_relationships).some(
        ([relationship_key, relationship]: [string, Relationship]) => {
          if (
            data.itemType === relationship["itemType"] &&
            data[relationship["property"]] === relationship["parent"]
          ) {
            const selected_relationship: SelectedRelationship = {
              ...relationship,
              parent_nodeId: data.nodeId,
              environment: data.environment,
              imageName: data.imageName,
            };
            setSelectedRelationship(selected_relationship);
            return true;
          }
        },
      );
    };

    return (
      <>
        {canBeParent ? (
          <>
            {isSelected ? (
              <button
                className={"workers-button hide-workers"}
                title={"Hide children"}
                onClick={() => {
                  setSelectedRelationship({});
                }}
              >
                <BsDiagram3 size={"1.5rem"} />
              </button>
            ) : (
              <button
                className={"workers-button"}
                onClick={() => {
                  select_relationship();
                }}
                title={"Show the workers instances using this distributor"}
              >
                <BsDiagram3Fill size={"1.5rem"} />
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  },
);

export default ChildrenButton;
