import HostNodeInterface from "../Interfaces/HostNodeInterface";
import BuildNodeInterface from "../Interfaces/BuildNodeInterface";

export function isHostNodeInterface(
  data: HostNodeInterface | BuildNodeInterface,
): data is HostNodeInterface {
  return (data as HostNodeInterface).isChild !== undefined;
}

// export function isBuildNodeInterface(
//   data: BuildNodeInterface | HostNodeInterface,
// ): data is BuildNodeInterface {
//   return (data as BuildNodeInterface).isChild === undefined;
// }
