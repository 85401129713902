import React, {FC, useEffect, useRef, useState} from "react";
import Draggable from "react-draggable";
import {IoIosCloseCircle} from "react-icons/io";
import {get_metrics_graph} from "../../Helpers/get_data";
import {FiLoader} from "react-icons/fi";
import PossibleMetricsInterface from "../../Interfaces/PossibleMetricsInterface";
import {FaInfoCircle} from "react-icons/fa";
import {Tooltip} from "react-tooltip";

const NodeMetricGraph: FC<{ metricsToUse: PossibleMetricsInterface[] }> = ({metricsToUse}) => {
    const [nodeMetricGraphOpen, setNodeMetricGraphOpen] = useState(localStorage.getItem("node_metric_graph_open") === "true");
    const [nodeOpen, setNodeOpen] = useState({env: "null", var: "null"});
    const [nodeMetricGraphPosition, setNodeMetricGraphPosition] = useState(JSON.parse(localStorage.getItem("node_metric_graph_position") || '{"x": 800, "y": 100}'));
    const [nodeMetricGraph, setNodeMetricGraph] = useState(localStorage.getItem("node_metric_graph") || "");
    const [payloadImg, setPayloadImg] = useState("");
    const [type, setType] = useState<string>(localStorage.getItem("node_graph_type") || "");
    const [waitingForGraph, setWaitingForGraph] = useState(false);
    const [usingMetrics, setUsingMetrics] = useState<string[]>([""]);
    const [days, setDays] = useState<string>("7");
    const hasPageBeenRendered = useRef(false);

    useEffect(() => {
        const handleNodeMetricGraphOpen = () => {
            setNodeMetricGraphOpen(localStorage.getItem("node_metric_graph_open") === "true");
            setType(localStorage.getItem("node_graph_type") || "");
            setNodeMetricGraph(localStorage.getItem("node_metric_graph") || "");
            setNodeOpen(JSON.parse(localStorage.getItem("node_open") || '{"env": "null", "var": "null"}'));
        };

        window.addEventListener('node_metric_graph_open', handleNodeMetricGraphOpen);

        return () => {
            window.removeEventListener('node_metric_graph_open', handleNodeMetricGraphOpen);
        };
    }, []);

    useEffect(() => {
        const metrics_requested = metricsToUse.find((e) => e.item_type === type);
        let metrics: string[] = metrics_requested ? metrics_requested.possible_metrics : [];
        const index = metrics.indexOf("Run_time");
        if (index > -1) {
            metrics.splice(index, 1);
        }
        setUsingMetrics(metrics);
    }, [type, metricsToUse]);

    useEffect(() => {
        setNodeMetricGraph(payloadImg);
        localStorage.setItem("node_metric_graph", payloadImg);
        setWaitingForGraph(false);
    }, [payloadImg]);

    useEffect(() => {
        let isCurrent = true;
        const requestNewGraph = async () => {
            setWaitingForGraph(true);
            const aux_str = await get_metrics_graph(usingMetrics, [nodeOpen.var], [nodeOpen.env], days);
            if (isCurrent) {
                setPayloadImg(aux_str);
            }
        };
        if (hasPageBeenRendered.current && usingMetrics.length > 0) {
            requestNewGraph()
        }
        hasPageBeenRendered.current = true;
        return () => {
            isCurrent = false;
        }
    }, [metricsToUse, days, usingMetrics]);


    const handleStop = (event: any, dragElement: any) => {
        const newPosition = {x: dragElement.x, y: dragElement.y};
        setNodeMetricGraphPosition(newPosition);
        localStorage.setItem("node_metric_graph_position", JSON.stringify(newPosition));
    };

    const title = () => {
        if (nodeOpen.env && nodeOpen.var) {
            return `${nodeOpen.env} ${nodeOpen.var}`;
        } else {
            return "Error in finding what node we have open";
        }
    };

    const toggleRunTime = () => {
        const newMetrics = [...usingMetrics];
        const index = newMetrics.indexOf("Run_time");
        if (index > -1) {
            newMetrics.splice(index, 1);
        } else {
            newMetrics.push("Run_time");
        }
        setUsingMetrics(newMetrics);
    };

    return (
        <>
            {nodeMetricGraphOpen && nodeMetricGraph ? (
                <Draggable onStop={handleStop} position={nodeMetricGraphPosition} cancel={"select"}>
                    <div className="node-metric-graph abs">
                        <header className="metric-graph-title">
                            <div className="top-left">
                                <label htmlFor="how-far-back">Select Days:</label>
                                <select id="how-far-back" value={days} onChange={(val) => setDays(val.target.value)}>
                                    <option value={7}>7</option>
                                    <option value={30}>30</option>
                                    <option value={90}>90</option>
                                </select>
                            </div>
                            {type !== "build" ? (
                                <div className="top-right">
                                    <input
                                        type="checkbox"
                                        checked={usingMetrics.includes("Run_time")}
                                        onChange={toggleRunTime}
                                        id="checkbox"
                                    />
                                    <label htmlFor="checkbox">Include RunTime</label>
                                </div>
                            ) : null}
                            {title()}
                            <a className={"abs right0 bottom node-footer"} data-tooltip-id="metrics-explainer">
                                <FaInfoCircle/>
                            </a>

                            <Tooltip id="metrics-explainer"
                                     place={"bottom"}
                            >
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <span>Metrics are the amount of time spent in a status for instances</span>
                                    <span>of the same Environment and Variant as this node</span>
                                    <span>host_time_taken is the overall time from beginning to running.</span>
                                    <span></span>
                                </div>
                            </Tooltip>
                        </header>
                        {waitingForGraph ? <FiLoader size="1.5rem" className="overlay-loader"/> : null}
                        <img alt="Graph of the metrics for the node selected"
                             src={`data:image/png;base64,${nodeMetricGraph}`}/>
                        <IoIosCloseCircle
                            className="close-container abs right0"
                            size="2rem"
                            onClick={() => {
                                setNodeMetricGraphOpen(false);
                                localStorage.setItem("node_metric_graph_open", "false");
                            }}
                        />
                    </div>
                </Draggable>
            ) : null}
        </>
    );
};

export default NodeMetricGraph;
