import React, { FC, useEffect, useState } from "react";

const TimeComponent: FC<{
  timestamp: string | null | {};
  label: string;
  add_label: boolean;
  row: boolean;
}> = React.memo(({ timestamp, label, add_label = true, row = true }) => {
  const [time_string, setTimeString] = useState<string>("");
  const [date_string, setDateString] = useState<string>("");

  useEffect(() => {
    if (!(typeof timestamp == "string")) {
      setTimeString("null");
    } else {
      const date = new Date(timestamp);
      const month =
        date.getUTCMonth() < 10
          ? "0" + (date.getUTCMonth() + 1)
          : date.getUTCMonth() + 1;
      const day =
        date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
      const hours =
        date.getUTCHours() < 10 ? "0" + date.getUTCHours() : date.getUTCHours();
      const minutes =
        date.getUTCMinutes() < 10
          ? "0" + date.getUTCMinutes()
          : date.getUTCMinutes();
      const seconds =
        date.getUTCSeconds() < 10
          ? "0" + date.getUTCSeconds()
          : date.getUTCSeconds();
      setTimeString(date.getUTCFullYear() + "-" + month + "-" + day);
      setDateString(hours + ":" + minutes + ":" + seconds);
    }
  }, [timestamp]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: row ? "row" : "column",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      {/*{add_label ? <div style={{ paddingRight: "5px" }}>{label}: </div> : <></>}*/}

      <div>{date_string}</div>
      <div style={{ paddingLeft: row ? 5 : 0 }}>{time_string}</div>
    </div>
  );
});

export default TimeComponent;
