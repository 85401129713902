import React, { FC } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { MdDownloading, MdOutlinePendingActions } from "react-icons/md";
import { MdOutlineRunningWithErrors } from "react-icons/md";
import { VscVmRunning } from "react-icons/vsc";
import { MdOutlineRestartAlt } from "react-icons/md";
import { BiSolidMessageAltError } from "react-icons/bi";
import { BiSolidErrorAlt } from "react-icons/bi";
import { IoCloudDownload } from "react-icons/io5";
import { BsFillSignStopFill } from "react-icons/bs";
import { FiLoader } from "react-icons/fi";
import { RiIndeterminateCircleFill } from "react-icons/ri";
import { MdStart } from "react-icons/md";

const StatusIcon: FC<{ status: string }> = ({ status }) => {
  const icon_size = "1.2rem";
  switch (status) {
    case "SUCCESS":
      return <FaCheckCircle size={icon_size} />;
    case "STARTED":
      return <MdStart size={icon_size} />;
    case "Late":
      return <MdOutlineRunningWithErrors size={icon_size} />;
    case "slightly_late":
      return <MdOutlineRunningWithErrors size={icon_size} />;
    case "running_old_image":
      return <BiSolidMessageAltError size={icon_size} />;
    case "no_build_match":
      return <BiSolidErrorAlt size={icon_size} />;
    case "Starting":
      return <MdOutlineRestartAlt size={icon_size} />;
    case "Provisioning":
      return <FiLoader size={icon_size} />;
    case "Downloading":
      return <MdDownloading size={icon_size} />;
    case "Running":
      return <VscVmRunning size={icon_size} />;
    case "Pending-restart":
      return <MdOutlinePendingActions size={icon_size} />;
    case "Fetching":
      return <IoCloudDownload size={icon_size} />;
    case "Stopped":
      return <BsFillSignStopFill size={icon_size} />;
    case "Terminated":
      return <RiIndeterminateCircleFill size={icon_size} />;
    default:
      return <IoIosWarning size={icon_size} />;
  }
};

export default StatusIcon;
