import React, { FC, useEffect, useRef, useState } from "react";
import "reactflow/dist/style.css";
import ImageNode from "../ReactFlowComponents/ImageNode";
import {
  Controls,
  Edge,
  MarkerType,
  Node,
  ReactFlow,
  ReactFlowInstance,
  ReactFlowProvider,
} from "reactflow";
import { FaInfoCircle } from "react-icons/fa";
import LoadingComponent from "../LoadingComponent";
import Host from "../../Interfaces/HostInterface";
import Build from "../../Interfaces/BuildInterface";
import { get_host_color_from_status } from "../../Helpers/helpers";
import ImageGroup from "../../Interfaces/ImageGroupInterface";
import PossibleMetricsInterface from "../../Interfaces/PossibleMetricsInterface";
import parent_child_relationships from "../../Configuration/parent_child_relationships";
import Relationship from "../../Interfaces/RelationshipInterface";
import NoChildrenNode from "../ReactFlowComponents/NoChildrenNode";
import SystemNode from "../ReactFlowComponents/SystemNode";
import { parse, differenceInHours } from "date-fns";
import SelectedRelationship from "../../Interfaces/SelectedRelationshipInterface";

const nodeTypes = {
  system_node: SystemNode,
  image: ImageNode,
  no_children_info: NoChildrenNode,
};

const CatalogImagesDiagram: FC<{
  buildsVisible: boolean;
  variantFilters: string[];
  appFilters: string[];
  sysNameFilters: string[];
  envFilters: string[];
  setInfoData: any;
  infoData: Build | Host | null;
  imageGroups: ImageGroup[];
  orphan_hosts: Host[] | never[];
  orphan_builds: Build[] | never[];
  find_orphans: Boolean;
  setNodes: any;
  nodes: Node[];
  itemTypeFilters: string[];
  metricsToUse: PossibleMetricsInterface[];
  setSelectedRelationship: any;
  selectedRelationship: SelectedRelationship | null;
  setInfoPosition: any;
}> = ({
  buildsVisible,
  variantFilters,
  appFilters,
  sysNameFilters,
  envFilters,
  setInfoData,
  infoData,
  imageGroups,
  orphan_hosts,
  orphan_builds,
  find_orphans,
  setNodes,
  nodes,
  itemTypeFilters,
  metricsToUse,
  setSelectedRelationship,
  selectedRelationship,
  setInfoPosition,
}) => {
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;

  const [connections, setConnections] = useState<Edge[]>([]);
  const [loading, setLoading] = useState(true);
  const [loopsComputed, setLoopsComputed] = useState(false);
  const [maxHeight, setMaxHeight] = useState(innerHeight);
  const [minHeight, setMinHeight] = useState(0);
  const [windowHeight, setWindowHeight] = useState(innerHeight);
  const [windowWidth, setWindowWidth] = useState(innerWidth);
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);

  useEffect(() => {
    if (reactFlowInstance.current) {
      reactFlowInstance.current.setCenter(innerWidth / 2, innerHeight / 2, {
        zoom: 1,
      });
    }
  }, [buildsVisible, variantFilters, appFilters, sysNameFilters, envFilters]);

  const check_overlapping_nodes = (shown_nodes: Node[]) => {
    shown_nodes.forEach((node1) => {
      shown_nodes.forEach((node2) => {
        if (node1.id !== node2.id) {
          if (
            node1.position.x === node2.position.x &&
            node1.position.y === node2.position.y
          ) {
            console.log("The following nodes have the same x and y!!!");
            console.log({ node1 });
            console.log({ node2 });
          }
        }
      });
    });
  };

  const find_node_id = (node_array: Node[], node_id: string) => {
    let node_found = false;
    node_array.forEach((node) => {
      if (node.id === node_id) {
        node_found = true;
      }
    });
    return node_found;
  };

  const find_connection_by_id = (build_id: string, edges_array: Edge[]) => {
    let connection_found = false;
    edges_array.forEach((edge) => {
      if (edge.source === build_id || edge.target === build_id) {
        connection_found = true;
      }
    });
    return connection_found;
  };

  const get_parent_height = (aux_nodes: Node[]) => {
    let parent_height = 0;
    aux_nodes.forEach((node) => {
      if (
        selectedRelationship &&
        node.data.data &&
        node.data.data.nodeId === selectedRelationship.parent_nodeId
      ) {
        parent_height = node.position.y;
        return true;
      }
    });
    return parent_height;
  };

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const generate_host_node = ({
      node_data,
      pos_y,
      pos_x,
      is_parent_node,
      last_image,
      older_than_24h,
      missing_parent = false,
    }: {
      node_data: Host;
      pos_y: number;
      pos_x: number;
      is_parent_node: boolean;
      last_image: boolean;
      older_than_24h: boolean;
      missing_parent?: boolean;
    }) => {
      const { backgroundColor, fontColor } = get_host_color_from_status(
        node_data.status,
        last_image,
        older_than_24h,
      );
      return {
        id: node_data.nodeId,
        position: { x: pos_x, y: pos_y },
        data: {
          data: node_data,
          setInfoData: setInfoData,
          node_selected: infoData && infoData.nodeId === node_data.nodeId,
          metricsToUse: metricsToUse,
          setSelectedRelationship: setSelectedRelationship,
          isSelected: selectedRelationship?.parent_nodeId === node_data.nodeId,
          canBeParent: is_parent_node,
          setInfoPosition: setInfoPosition,
          isChild: !is_parent_node,
          last_image: last_image,
          // show_outdated_image: !last_image && node_data.status !== "Terminated",
          older_than_24h: older_than_24h,
          missing_parent: missing_parent,
        },
        style: {
          backgroundColor: backgroundColor,
          color: fontColor,
        },
        type: "system_node",
      };
    };

    const generate_build_node = ({
      image_name,
      build_data,
      pos_y,
      pos_x,
    }: {
      image_name?: string;
      build_data: Build;
      pos_y: number;
      pos_x: number;
    }) => {
      const { backgroundColor, fontColor } = get_host_color_from_status(
        build_data.status,
      );
      return {
        id: image_name ? image_name : build_data.nodeId,
        position: { x: pos_x, y: pos_y },
        data: {
          data: build_data,
          setInfoData: setInfoData,
          node_selected: infoData && infoData.nodeId === build_data.nodeId,
          metricsToUse: metricsToUse,
          setInfoPosition: setInfoPosition,
        },
        style: {
          backgroundColor: backgroundColor,
          color: fontColor,
        },
        type: "system_node",
      };
    };

    const generate_edge = ({
      target,
      source,
    }: {
      target: string;
      source: string;
    }) => {
      return {
        id: "e-" + target + "-" + source,
        target: target,
        source: source,
        markerEnd: {
          type: MarkerType.Arrow,
          width: 10,
          height: 10,
          color: "black",
        },
        style: {
          strokeWidth: 2,
          stroke: "black",
        },
      };
    };

    const is_older_than_24h = (image_name: string) => {
      function isMoreThan24Hours(dateString: string): boolean {
        const day = dateString.split("_")[0];
        const time = dateString.split("_")[1].replaceAll("-", ":");
        const parsedDate = parse(
          `${day} ${time}`,
          "yyyy-MM-dd HH:mm:ss",
          new Date(),
        );
        const now = new Date();
        const difference = differenceInHours(now, parsedDate);
        return difference > 24;
      }

      const img_time_regex = /\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2}/;
      // Use match to find the substring that matches the pattern
      const matches = image_name.match(img_time_regex);

      // return older_than_24h;
      if (matches && matches[0] && isMoreThan24Hours(matches[0])) {
        return true;
      }
      return false;
    };
    const is_last_image = (image_group: ImageGroup, node: Host) => {
      return (
        //remove this exception eventually
        image_group.builds.length !== 0 ||
        (node.environment === "rpa2-prod-ireland" &&
          (node.variant === "class-dist-oversized" ||
            node.variant === "class-distributor" ||
            node.variant === "class-worker-oversized" ||
            node.variant === "class-worker")) ||
        //remove this exception eventually
        (node.environment === "rpa2-prod-nvirginia" &&
          (node.variant === "class-dist-oversized" ||
            node.variant === "class-distributor" ||
            node.variant === "class-worker-oversized" ||
            node.variant === "class-worker")) ||
        node.status === "Preparing"
      );
    };

    const get_y_from_child = (child_node_id: string, list_of_nodes: Node[]) => {
      let pos_y = 0;
      list_of_nodes.forEach((node) => {
        if (node.id === child_node_id) {
          pos_y = node.position.y;
        }
      });
      return pos_y;
    };

    setLoading(true);
    const aux_edges: Edge[] = [];
    const aux_nodes: Node[] = [];
    const calculate_current_height = (index: number) => {
      return 100 + innerHeight * 0.25 * index;
    };

    const in_between_horizontal = innerWidth * 0.04;
    const node_width = innerWidth * 0.2;
    let real_index = 0;
    let current_height = calculate_current_height(real_index);
    let hosts_hidden = true;
    let builds_hidden = true;
    let host_x = in_between_horizontal * 3 + node_width * 2;

    for (let hostType of itemTypeFilters) {
      if (hostType.toLowerCase().includes("host")) {
        hosts_hidden = false;
        break;
      }
    }

    for (let hostType of itemTypeFilters) {
      if (hostType.toLowerCase().includes("build")) {
        builds_hidden = false;
        break;
      }
    }
    let distributors = new Map();

    let n_children_shown = 0;
    let current_worker_index = 0;
    let max_worker_height = 0;
    let min_worker_height = 0;
    const build_parent_children: { parent: string; child: string }[] = [];
    const orphan_host_children = [];

    Object.entries(parent_child_relationships).forEach(
      ([relationship_key, relationship]: [string, Relationship]) => {
        if (relationship.type === "build") {
          imageGroups.forEach((image_group) => {
            image_group.builds.forEach((build) => {
              imageGroups.forEach((image_group2) => {
                image_group2.builds.forEach((build2) => {
                  if (
                    build.itemType === relationship.itemType &&
                    build.environment === relationship.environment &&
                    build[relationship.property] === relationship.children &&
                    build2.environment === relationship.environment &&
                    build2[relationship.property] === relationship.parent
                  ) {
                    build_parent_children.push({
                      parent: build2.nodeId,
                      child: image_group.image_name,
                    });
                  }
                });
              });
            });
          });
        }
      },
    );

    imageGroups.forEach((image_group) => {
      let image_to_be_added = false;
      let showing_at_least_1_host = false;
      current_height = calculate_current_height(real_index);
      const image_group_initial_height = current_height;

      // Create hosts nodes
      if (!hosts_hidden) {
        // calculate number of children to show
        image_group.hosts.forEach((e) => {
          // Add hosts if allowed by the filters
          if (
            variantFilters.includes(e.variant + "") &&
            appFilters.includes(e.application + "") &&
            sysNameFilters.includes(e.systemName + "") &&
            envFilters.includes(e.environment + "") &&
            itemTypeFilters.includes(e.itemType + "")
          ) {
            if (
              selectedRelationship &&
              selectedRelationship.imageName === e.imageName
            ) {
              if (
                e.itemType === selectedRelationship["itemType"] &&
                e[selectedRelationship["property"]] ===
                  selectedRelationship["parent"] &&
                e.environment === selectedRelationship["environment"]
              ) {
                distributors.set(e.imageName, e.nodeId);
              }
              if (
                e.itemType === selectedRelationship["itemType"] &&
                e[selectedRelationship["property"]] ===
                  selectedRelationship["children"] &&
                e.environment === selectedRelationship["environment"]
              ) {
                n_children_shown += 1;
              }
            }
          }
        });

        // Create host nodes for parents
        image_group.hosts.forEach((e) => {
          // Add hosts if allowed by the filters
          if (
            variantFilters.includes(e.variant + "") &&
            appFilters.includes(e.application + "") &&
            sysNameFilters.includes(e.systemName + "") &&
            envFilters.includes(e.environment + "") &&
            itemTypeFilters.includes(e.itemType + "")
          ) {
            // Check if a node is a children node or a parent node
            let is_children_node = false;
            let is_parent_node = false;
            let current_relationship: Relationship | null = null;
            Object.entries(parent_child_relationships).forEach(
              ([relationship_key, relationship]: [string, Relationship]) => {
                if (
                  relationship.type === "host" &&
                  e.itemType === relationship.itemType
                ) {
                  if (e[relationship.property] === relationship.children) {
                    is_children_node = true;
                    current_relationship = relationship;
                  } else if (e[relationship.property] === relationship.parent) {
                    is_parent_node = true;
                  }
                }
              },
            );
            let has_parent = false;
            if (current_relationship !== null) {
              image_group.hosts.forEach((e2) => {
                if (
                  e2.nodeId !== e.nodeId &&
                  current_relationship &&
                  e2.itemType === current_relationship.itemType &&
                  e2[current_relationship.property] ===
                    current_relationship.parent
                ) {
                  has_parent = true;
                }
              });
            }
            if (is_children_node && !has_parent) {
              orphan_host_children.push(e.nodeId);
            }
            // Show all hosts that are not children and children without parent
            if (!is_children_node || !has_parent) {
              current_height = calculate_current_height(real_index);
              const last_image = is_last_image(image_group, e);

              const older_than_24h = is_older_than_24h(image_group.image_name);
              // Create host from image group
              const aux_obj = generate_host_node({
                node_data: e,
                pos_y: current_height,
                pos_x: host_x,
                is_parent_node: is_parent_node,
                last_image: last_image,
                older_than_24h: older_than_24h,
                missing_parent: is_children_node && !has_parent,
              });

              aux_nodes.push(aux_obj);
              if (!last_image || (is_children_node && !has_parent)) {
                image_to_be_added = true;
              }
              real_index += 1;
              showing_at_least_1_host = true;
            }

            // Make line connections between hosts and images
            // if (buildsVisible) {
            const single_img_edge: Edge = generate_edge({
              target: e.nodeId,
              source: e.imageName,
            });
            // If there is a selected relationship, then update the line connecting to the parent
            if (
              selectedRelationship &&
              e.itemType === selectedRelationship.itemType &&
              e[selectedRelationship.property] ===
                selectedRelationship.children &&
              selectedRelationship.imageName === e.imageName &&
              selectedRelationship.environment === e.environment
            ) {
              single_img_edge.source = distributors.get(e.imageName);
            }
            aux_edges.push(single_img_edge);
          }
        });

        // Create children nodes from selected parent
        let parent_has_children = false;
        image_group.hosts.forEach((e) => {
          if (
            variantFilters.includes(e.variant + "") &&
            appFilters.includes(e.application + "") &&
            // sysNameFilters.includes(build.systemName + "") &&
            envFilters.includes(e.environment + "") &&
            itemTypeFilters.includes(e.itemType + "")
          ) {
            // We only show the children of the selected relationship (contains info about the configuration) and selected distributor (contains the node_id of the parent)
            if (
              selectedRelationship &&
              e.itemType === selectedRelationship.itemType &&
              e[selectedRelationship.property] ===
                selectedRelationship.children &&
              selectedRelationship.imageName === e.imageName &&
              selectedRelationship.environment === e.environment
            ) {
              parent_has_children = true;

              // get parent height
              let distributor_height = get_parent_height(aux_nodes);
              current_worker_index += 1;
              let worker_height =
                distributor_height +
                innerHeight *
                  0.25 *
                  (current_worker_index - (1 + n_children_shown) / 2);
              // (1 + (n_children_shown % 2)) / 2);
              if (n_children_shown === 1) {
                worker_height = distributor_height;
              }
              if (worker_height < min_worker_height) {
                min_worker_height = worker_height;
              }
              if (worker_height > max_worker_height) {
                max_worker_height = worker_height;
              }
              const last_image = is_last_image(image_group, e);

              const older_than_24h = is_older_than_24h(image_group.image_name);

              const aux_obj = generate_host_node({
                node_data: e,
                pos_y: worker_height,
                pos_x: host_x + in_between_horizontal + node_width,
                is_parent_node: false,
                last_image: last_image,
                older_than_24h: older_than_24h,
              });
              aux_nodes.push(aux_obj);
            }
          }
        });

        // Show node error when there are no children
        if (
          !parent_has_children &&
          selectedRelationship?.imageName === image_group.image_name
        ) {
          let parent_height = get_parent_height(aux_nodes);
          const aux_obj = {
            id: "no_children_info",
            data: {},
            position: {
              x: host_x + in_between_horizontal + node_width,
              y: parent_height,
            },
            type: "no_children_info",
          };
          aux_nodes.push(aux_obj);
        }
      }

      const image_y = (image_group_initial_height + current_height) / 2;

      // Creating build nodes
      if (buildsVisible && !builds_hidden) {
        image_group.builds.forEach((build) => {
          if (
            variantFilters.includes(build.variant + "") &&
            appFilters.includes(build.application + "") &&
            // sysNameFilters.includes(build.systemName + "") &&
            envFilters.includes(build.environment + "") &&
            itemTypeFilters.includes(build.itemType + "")
          ) {
            // if the build is a parent, get all children
            // Create build nodes
            let is_build_child = false;
            let is_build_parent = false;
            let build_parent = "";
            build_parent_children.forEach((aux_rel) => {
              if (aux_rel.child === image_group.image_name) {
                is_build_child = true;
                build_parent = aux_rel.parent;
              } else if (aux_rel.parent === build.nodeId) {
                is_build_parent = true;
              }
            });

            let build_x_pos = in_between_horizontal;
            if (is_build_parent) {
              return;
            } else if (is_build_child) {
              build_x_pos =
                in_between_horizontal + node_width + in_between_horizontal;
              // Create edge between builds and build children
              const single_img_edge: Edge = generate_edge({
                source: build_parent,
                target: image_group.image_name,
              });
              aux_edges.push(single_img_edge);
            }
            const aux_obj = generate_build_node({
              image_name: image_group.image_name,
              build_data: build,
              pos_y: image_y,
              pos_x: build_x_pos,
            });
            aux_nodes.push(aux_obj);
            if (!showing_at_least_1_host) {
              real_index += 1;
            }
          }
        });
      }

      // Adding image node
      if (
        image_group.builds.length === 0 &&
        image_group.hosts.length > 0 &&
        image_to_be_added
      ) {
        const older_than_24h = is_older_than_24h(image_group.image_name);
        const single_img = {
          id: image_group.image_name,
          position: {
            x: in_between_horizontal * 2 + node_width,
            y: image_y,
          },
          data: {
            label: image_group.image_name,
            last_image: false,
            older_than_24h: older_than_24h,
          },
          type: "image",
        };
        aux_nodes.push(single_img);
      }
    });

    // Build parents
    imageGroups.forEach((image_group) => {
      if (buildsVisible && !builds_hidden) {
        image_group.builds.forEach((build) => {
          if (
            variantFilters.includes(build.variant + "") &&
            appFilters.includes(build.application + "") &&
            // sysNameFilters.includes(build.systemName + "") &&
            envFilters.includes(build.environment + "") &&
            itemTypeFilters.includes(build.itemType + "")
          ) {
            // if the build is a parent, get all children
            // Create build nodes
            let build_child_count = 0;
            let build_child_y_pos = 0;
            let is_build_parent = false;
            build_parent_children.forEach((aux_rel) => {
              if (aux_rel.parent === build.nodeId) {
                is_build_parent = true;
                if (find_node_id(aux_nodes, aux_rel.child)) {
                  build_child_count += 1;
                  build_child_y_pos += get_y_from_child(
                    aux_rel.child,
                    aux_nodes,
                  );
                }
              }
            });
            let build_parent_y_pos = 0;
            if (build_child_count > 0) {
              build_parent_y_pos = build_child_y_pos / build_child_count;
            } else {
              build_parent_y_pos = calculate_current_height(real_index);
            }

            if (is_build_parent) {
              if (!find_connection_by_id(build.nodeId, aux_edges)) {
                real_index += 1;
              }
              // Create edge between builds and build children
              const single_img_edge: Edge = generate_edge({
                source: image_group.image_name,
                target: build.nodeId,
              });
              aux_edges.push(single_img_edge);

              const aux_obj = generate_build_node({
                build_data: build,
                pos_y: build_parent_y_pos,
                pos_x: in_between_horizontal,
              });
              aux_nodes.push(aux_obj);
            }
          }
        });
      }
    });

    // Create  orphan host nodes if allowed by the filters
    if (!hosts_hidden && find_orphans) {
      orphan_hosts.forEach((e: Host) => {
        if (
          variantFilters.includes(e.variant + "") &&
          appFilters.includes(e.application + "") &&
          sysNameFilters.includes(e.systemName + "") &&
          envFilters.includes(e.environment + "") &&
          itemTypeFilters.includes(e.itemType + "")
        ) {
          current_height = calculate_current_height(real_index);
          const aux_obj = generate_host_node({
            node_data: e,
            pos_y: current_height,
            pos_x: host_x,
            is_parent_node: true,
            last_image:
              e.environment === "rpa2-prod-ireland" &&
              (e.variant === "class-dist-oversized" ||
                e.variant === "class-distributor"),
            older_than_24h: false,
          });
          aux_nodes.push(aux_obj);
          real_index += 1;
        }
      });
    }

    // Add orphan hosts if allowed by the find_orphans filter
    if (!builds_hidden && find_orphans) {
      // Create orphan builds nodes
      orphan_builds.forEach((e: Build) => {
        if (
          variantFilters.includes(e.variant + "") &&
          appFilters.includes(e.application + "") &&
          envFilters.includes(e.environment + "") &&
          itemTypeFilters.includes(e.itemType + "")
        ) {
          current_height = calculate_current_height(real_index);
          const aux_obj = generate_build_node({
            build_data: e,
            pos_y: current_height,
            pos_x: in_between_horizontal,
          });
          aux_nodes.push(aux_obj);
          real_index += 1;
        }
      });
    }

    current_height = calculate_current_height(real_index);
    setMinHeight(Math.min(0, min_worker_height));
    setMaxHeight(Math.max(current_height, max_worker_height));
    setConnections(aux_edges);
    setNodes(aux_nodes);
    setLoading(false);
    setLoopsComputed(true);

    // Uncomment the following function to check if there are nodes with the same x and y
    // check_overlapping_nodes(aux_nodes);
  }, [
    buildsVisible,
    variantFilters,
    appFilters,
    sysNameFilters,
    envFilters,
    itemTypeFilters,
    imageGroups,
    orphan_hosts,
    orphan_builds,
    find_orphans,
    selectedRelationship,
    infoData,
    windowHeight,
    windowWidth,
  ]);

  // const onLoad = useCallback((reactFlowInstance: ReactFlowInstance) => {
  //   reactFlowInstance.fitView();
  // }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flex: 1,
        position: "relative",
        zIndex: 1,
        userSelect: "all",
      }}
    >
      {loading && loopsComputed ? (
        <LoadingComponent />
      ) : (
        <>
          {nodes.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <FaInfoCircle size={100} />
              <h1>There is no information about the build and host status</h1>
            </div>
          ) : (
            <ReactFlowProvider>
              <div
                style={{ width: "100%", height: "100%" }}
                className="no-select"
              >
                <ReactFlow
                  onInit={(instance) => (reactFlowInstance.current = instance)}
                  nodes={nodes}
                  edges={connections}
                  nodeTypes={nodeTypes}
                  panOnScroll={true}
                  minZoom={0.5}
                  maxZoom={1}
                  translateExtent={[
                    [0, minHeight - 100],
                    [innerWidth, 300 + maxHeight],
                  ]}
                  // onLoad={(instance) => setTimeout(() => instance.fitView(), 0)}
                  elevateNodesOnSelect={true}
                  elementsSelectable={true}
                  // fitView
                  fitViewOptions={{
                    padding: 0,
                    duration: 500,
                  }}
                  // onNodesChange={onLoad}
                  // onInit={onLoad}
                  // onInit={(instance) => instance.fitview()}
                >
                  <Controls position={"bottom-right"} showInteractive={false} />
                </ReactFlow>
              </div>
            </ReactFlowProvider>
          )}
        </>
      )}
    </div>
  );
};

export default CatalogImagesDiagram;
