import React, {FC, useEffect, useState} from "react";
import "react-notifications/lib/notifications.css";

const FilterCheckbox: FC<{
    env: string;
    filters: string[];
    selectFilters: any;
    diffFromProfile: Set<string>;
}> = ({env, filters, selectFilters, diffFromProfile}) => {
    const [isInDiff, setIsInDiff] = useState<boolean>(false);
    useEffect(() => {
        setIsInDiff(diffFromProfile.has(env))
    }, [diffFromProfile]);
    return (
        <div onClick={() => selectFilters(env)} className={"pointer"}>
            <></>
            <input
                id={env}
                type="checkbox"
                checked={filters.includes(env)}
                onChange={() => {
                    selectFilters(env);
                }}
            />
            {env && <label htmlFor={env}>
                {env.charAt(0).toUpperCase() + env.slice(1)}
            </label>}
            {isInDiff ? <span className={"unsaved"}>*</span> : <></>}
        </div>
    );
};
export default FilterCheckbox;
