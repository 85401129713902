import React, { FC } from "react";
import { MdError } from "react-icons/md";

const ErrorComponent: FC<{ errorMessage: string }> = ({ errorMessage }) => {
  return (
    <h1
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        width: "100%",
        padding: "auto",
        // backgroundColor: "red",
      }}
    >
      <div
        style={{
          width: "66%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MdError color={"red"} size={"5rem"} />
        {errorMessage}
      </div>
    </h1>
  );
};
export default ErrorComponent;
