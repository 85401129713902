import Build from "../Interfaces/BuildInterface";
import Host from "../Interfaces/HostInterface";
import axios from "axios";
import ImageGroup from "../Interfaces/ImageGroupInterface";
import AllCatalogInterface from "../Interfaces/AllCatalogInterface";
import PossibleMetricsInterface from "../Interfaces/PossibleMetricsInterface";

const invoke_url = "du6sdukjb2-vpce-0aa6e63f2c04fd81b.execute-api.eu-west-1.amazonaws.com/prod/statuses"
export const get_data = async (type: string) => {
    const url =
        `https://${invoke_url}` +
        type +
        "/?sorting_field=image_name";
    let res_data: Build[] | Host[] | never[] = [];
    await axios
        .get(url, {})
        .then(function (response) {
            res_data = response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
    // console.log({res_data});
    return res_data;
};

export const get_builds_and_hosts_data = async () => {
    let hosts: Host[] | never[] = (await get_data("hosts")) as Host[] | never[];
    let builds: Build[] | never[] = (await get_data("builds")) as
        | Build[]
        | never[];
    return {hosts, builds};
};

export const get_images_group_data = async () => {
    const url =
        `https://${invoke_url}/images` +
        "/?sorting_field=image_name";
    // let res_data: ImageGroup = {};
    const res_data: ImageGroup[] = await axios
        .get(url, {})
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
            return [];
        });
    return res_data;
};

export const get_catalog_data = async () => {
    const seconds_till_timeout = 20;
    const url =
        `https://${invoke_url}/catalog/`;
    //  + "/?sorting_field=image_name";
    // let res_data: ImageGroup = {};
    const res_data: AllCatalogInterface | { error: string } = await axios
        .get(url, {
            timeout: seconds_till_timeout * 1000,
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            if (error.code === "ECONNABORTED") {
                console.log("Request timed out");
                return {
                    error:
                        "Request timed out after " +
                        seconds_till_timeout +
                        " seconds. Make sure you are connected to the internal network (VPN)!",
                };
            } else {
                console.log(error);
                return {error: error};
            }
        });

    return res_data;
};

export const get_possible_metrics = async () => {
    const url =
        `https://${invoke_url}/metrics/possible-metrics`;
    const res_data = await axios
        .get<PossibleMetricsInterface[]>(url, {})
        .then(function (response) {
            const data: PossibleMetricsInterface[] = response.data;
            return data;
        })
        .catch(function (error) {
            console.log(error);
            return null;
        });

    return res_data;
};
export const get_metrics_graph = async (
    metrics: string[],
    variants: string[],
    environments: string[],
    days: string = "7",
): Promise<string> => {
    const url =
        `https://${invoke_url}/metrics/metric-image`;
    return await axios
        .post(url, {
            environments: environments,
            variants: variants,
            metrics: metrics,
            days: Number(days),
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            // console.log(error);
            return null;
        });
};
