import React, { FC } from "react";
import "react-notifications/lib/notifications.css";
import { LuRefreshCw } from "react-icons/lu";

const RefreshIcon: FC<{
  setRefreshing: any;
  refreshing: boolean;
  setErrorMessage: any;
}> = ({ refreshing, setRefreshing, setErrorMessage }) => {
  return (
    <div className="refresh" style={{ bottom: 15, left: 15 }}>
      <button
        className={!refreshing ? "wheel-container" : "refresh-spinner"}
        disabled={refreshing}
        onClick={() => {
          setRefreshing(true);
          setErrorMessage("");
        }}
      >
        <LuRefreshCw className={"settings-icon"} size={50} />
      </button>
      <div
        className={!refreshing ? "hide-settings abs" : "abs"}
        style={{ fontWeight: 900 }}
      >
        {refreshing ? "Loading" : "Refresh"}
      </div>
    </div>
  );
};
export default RefreshIcon;
