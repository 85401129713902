import {FC} from "react";

const SuperHeader: FC<{ application: string; systemName?: string }> = ({
                                                                           application,
                                                                           systemName,
                                                                       }) => {
    return (
        <div className={"super-header"}>
            {systemName ? <div>{systemName}</div> : <></>}
            <div
                style={{paddingLeft: "5px", paddingRight: "5px"}}
                title={application}
                className={"ellipsis"}
            >
                {application}
            </div>
        </div>
    );
};

export default SuperHeader;
