import React from "react";
import "reactflow/dist/style.css";

export default function LoadingComponent() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        // padding: "5vw"
      }}
    >
      <div className={"loader"}>{/*<div>Loading</div>*/}</div>

      <h1 style={{ position: "absolute" }}>Loading</h1>

      {/*<CgSearchLoading size={100}/>*/}
      {/*<h1>Loading...</h1>*/}
    </div>
  );
}
