import React, {FC} from "react";
import {IoIosWarning} from "react-icons/io";

const HostNode: FC<{
    data: {};
}> = React.memo(({data}) => {
    return (
        <div
            style={{
                width: window.innerWidth * 0.2 + "px",
                backgroundColor: "red",
                fontWeight: "bold",
                color: "white",
                borderRadius: "50px",
            }}
            className={"node"}
        >
            <IoIosWarning size={"5rem"}/>
            <div>The selected parent doesn't have any children</div>
        </div>
    );
});

export default HostNode;
