import React, {FC} from "react";
import TimeComponent from "../ReactFlowComponents/NodeComponents/TimeComponent";
import Host from "../../Interfaces/HostInterface";
import Build from "../../Interfaces/BuildInterface";
import {IoIosCloseCircle} from "react-icons/io";
import {RiFileCopyFill} from "react-icons/ri";
import Draggable from "react-draggable";
import {FaQuestion} from "react-icons/fa";
import {handleCopyToClipboard} from "../../Helpers/helpers";

const CompleteInfo: FC<{
    infoData: Host | Build | null;
    setInfoData: any;
}> = ({infoData, setInfoData}) => {
    return (
        <>
            <Draggable>
                {infoData != null ? (
                    <div
                        style={{
                            position: "fixed",
                            display: infoData ? "flex" : "hidden",
                            flexDirection: "column",
                            right: 0,
                            bottom: 0,
                            backgroundColor: "lightgrey",
                            padding: "5px",
                            border: "1px solid black",
                            maxWidth: "33vw",
                            // borderTop: "1px solid black",
                            // borderLeft: "1px solid black",
                            zIndex: 2,
                            cursor: "grab",
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            {/*<div></div>*/}
                            <div
                                style={{
                                    display: "flex",
                                    flex: 5,
                                    fontSize: "1.2rem",
                                    justifyContent: "flex-start",
                                }}
                            >
                                System info
                            </div>

                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <FaQuestion
                                    style={{cursor: "help"}}
                                    size={"1.5rem"}
                                    title={"Click and drag to move this window"}
                                />
                                <IoIosCloseCircle
                                    className={"close-container"}
                                    title={"Close info window"}
                                    size={"2rem"}
                                    onClick={() => {
                                        setInfoData(null);
                                    }}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            {Object.keys(infoData).map((el: string, key: number) => {
                                return (
                                    <div
                                        key={infoData.nodeId + "_" + key}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            const text = infoData[el as keyof typeof infoData];
                                            if (
                                                typeof infoData[el as keyof typeof infoData] ==
                                                "string" ||
                                                typeof infoData[el as keyof typeof infoData] == "number"
                                            ) {
                                                handleCopyToClipboard(null, "" + text);
                                            } else {
                                                handleCopyToClipboard(null, JSON.stringify(text));
                                            }
                                        }}
                                        className={"pointer copy-info"}
                                        title={"Copy " + el + " value"}
                                    >
                                        <RiFileCopyFill/>
                                        <div
                                            className={"ellipsis"}
                                            style={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            <div className={"ellipsis"}>{el}:</div>
                                        </div>
                                        <div
                                            className={"ellipsis"}
                                            style={{
                                                fontWeight: "bold",
                                                flex: 2,
                                                display: "flex",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            {typeof infoData[el as keyof typeof infoData] ==
                                            "string" ||
                                            typeof infoData[el as keyof typeof infoData] ==
                                            "number" ? (
                                                <>
                                                    {el.slice(-2) === "Ts" ? (
                                                        <div className={"ellipsis"}>
                                                            <TimeComponent
                                                                timestamp={
                                                                    infoData[el as keyof typeof infoData]
                                                                }
                                                                label={""}
                                                                add_label={false}
                                                                row={true}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className={"ellipsis"}>
                                                            {infoData[el as keyof typeof infoData] + ""}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className={"ellipsis"}>
                                                    {JSON.stringify(
                                                        infoData[el as keyof typeof infoData],
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {/*</div>*/}

                        <button
                            className={"pointer"}
                            onClick={() => {
                                handleCopyToClipboard(infoData, null);
                            }}
                        >
                            <RiFileCopyFill/>
                            Copy all data
                        </button>
                    </div>
                ) : (
                    <></>
                )}
            </Draggable>
        </>
    );
};

export default CompleteInfo;
